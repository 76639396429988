import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {now} from "moment";

let timeout;
let calendar;
let eventSource;
let reload;
let queryParams = new URLSearchParams(window.location.search);
let fc_user_id;
let bH;

function getHeader() {
    if (window.innerWidth < 991) {
        return {
            left: 'title',
            right: 'prev,next',
            center: 'today'
        };

    } else {
        return {
            left: 'title',
            center: 'timeGridDay,timeGridWeek',
        }
    }
}


function storeHistory() {
    queryParams.forEach(function (e, k) {
        if (e === '') {
            queryParams.delete(k);
        }
    });
    if (queryParams.toString() !== '') {
        history.replaceState(null, null, "?" + queryParams.toString());
    } else {
        history.replaceState(null, null, '/');
    }

}


function showLoaderFC() {
    $(".preloader-container").css('display', 'flex');
    $(".timeout-item").html('Подождите...');
    timeout = window.setTimeout(function () {
        $(".timeout-item").html('Подождите...<br /><span class="text-danger">Если операция выполняется слишком долго (более 2 минут), - <br />перезагрузите страницу и повторите.</span>');
    }, 35000);
}

function hideLoaderFC() {
    $(".preloader-container").css('display', 'none');
    if (timeout != null) {
        window.clearTimeout(timeout)
    }
    $(".timeout-item").html('Подождите...');
}

function initCalendar() {
    if (calendar !== undefined) {
        calendar.destroy();
    }

    calendar = new Calendar(document.getElementById('calendar'), {
        views: {
            timeGridDay: {
                titleFormat: {year: 'numeric', month: 'long', day: '2-digit', weekday: 'short'}
            }
        },
        locale: ruLocale,
        plugins: [interactionPlugin, timeGridPlugin, dayGridPlugin],
        headerToolbar: getHeader(),
        initialDate: (now()),
        initialView: $(window).width() < 991 ? 'timeGridDay' : 'timeGridWeek',
        height: 'auto',
        stickyHeaderDates: true,
        slotMinTime: (start.isBefore(end) ? document.querySelector('meta[name=w-time-start]').getAttribute('content') : '00:00'),
        slotMaxTime: (start.isBefore(end) ? document.querySelector('meta[name=w-time-end]').getAttribute('content') : '23:59'),
        businessHours: bH,
        slotDuration: document.querySelector('meta[name=w-slot-length]').getAttribute('content'),
        selectConstraint: "businessHours",
        validRange: {
            start: now(),
            end: (new moment()).add(6, 'month')
        },
        events: eventSource,
        eventSourceSuccess: function (content, xhr) {
            hideLoaderFC();
        },

        windowResize: function (arg) {
            this.setOption('headerToolbar', getHeader());
            if (window.innerWidth < 991) {
                this.changeView('timeGridDay');
            }
        },

        loading: function (state) {
            if (state === true) {
                if ($(".modal.fade.show").length === 0 && $(".popover.show").length === 0 && $(".tooltip.show").length === 0) {
                    showLoaderFC();
                }
            } else {
                hideLoaderFC();
            }
        },
        dayHeaderDidMount: async function (el) {

        },
        eventDidMount: function (el) {
            el.el.classList.add('viewSchedule');
            el.el.setAttribute('data-id', el.event.id);
            let container = el.el.querySelector('.fc-event-title-container');
            if (container) {
                container.textContent = '';
            }
            //     if (el.event.extendedProps.patient) {
            //         container.insertAdjacentHTML('beforebegin', "<br />Пациент: " + el.event.extendedProps.patient) + '<br />';
            //         el.event.setProp('title', 'Услуга: ' + el.event.title);
            //     }
            //     container.insertAdjacentHTML('beforebegin', "<br />Кабинет: " + el.event.extendedProps.room);
            //     container.insertAdjacentHTML('beforebegin', "<br />Врач: " + el.event.extendedProps.doctor) + '<br />';
            //
            // }
            $(el.el).popover({
                title: el.event.extendedProps.tooltip_title,
                content: `Врач: ${el.event.extendedProps.doctor}<br />
                    Кабинет: ${el.event.extendedProps.room}`,
                html: true,
                placement: 'auto',
                trigger: 'hover',
                container: '#calendar'
            });
        }
    });

    calendar.render();
    let headerToolbar = document.querySelector('.fc-header-toolbar');
    let dayTitles = document.querySelector('.fc-scrollgrid-section-sticky .fc-scroller');

    if (headerToolbar && dayTitles) {
        $(window).scroll(function () {
            let offsetTopPosition = headerToolbar.getBoundingClientRect().top;
            if ($(window).scrollTop() > offsetTopPosition) {
                headerToolbar.classList.add('fixed');
                dayTitles.classList.add('fixed-days');
                dayTitles.style.top = headerToolbar.clientHeight + 'px';
                headerToolbar.style.width = $("#calendar").width() + 'px';
                $(".daysBlock").removeClass('d-none');
            } else {
                headerToolbar.classList.remove('fixed');
                dayTitles.classList.remove('fixed-days');
                dayTitles.style.top = 'auto';
            }
        });

        $(window).resize(function () {
            headerToolbar.style.width = $("#calendar").width() + 'px';
        });

    }


    if (reload !== undefined) {
        clearInterval(reload);
    }
    reload = setInterval(function () {
        calendar.refetchEvents();
        $('.popover').remove();
    }, 10000);

}

const start = moment(document.querySelector('meta[name=w-time-start]').getAttribute('content'), 'HH:mm');
const end = moment(document.querySelector('meta[name=w-time-end]').getAttribute('content'), 'HH:mm');


document.addEventListener('DOMContentLoaded', function () {

    // userSelector = document.querySelector('#fc_user_id');
    fc_user_id = queryParams.has('identity') ? queryParams.get('identity') : '';
    // userSelector.value = fc_user_id;
    let listElements = document.getElementsByClassName('sel-doctor');

    if (fc_user_id) {

        if (listElements.length) {
            listElements.forEach(function (el) {
                el.classList.remove('active');
            });
            let item;
            listElements.forEach(function (i) {
                if (i.getAttribute('data-id') === fc_user_id) {
                    i.classList.add('active');
                    document.querySelector('.not-selected').classList.add('d-none');
                }
            });
        }
    }

    if (listElements.length) {
        for (var i = 0; i < listElements.length; i++) {
            listElements[i].addEventListener('click', function (e) {
                e.preventDefault();
                listElements.forEach(function (el) {
                    el.classList.remove('active');
                });
                e.target.classList.add('active');
                fc_user_id = e.target.getAttribute('data-id');
                queryParams.set('identity', fc_user_id);
                storeHistory();
                document.querySelector('.not-selected').classList.add('d-none');
                eventSource.extraParams.identity = fc_user_id;
                if (calendar !== undefined) {
                    calendar.removeAllEventSources();
                    calendar.addEventSource(eventSource);
                } else {
                    if (reload !== undefined) {
                        clearInterval(reload);
                    }
                    initCalendar();

                }
            }, false);
        }
    }

    // userSelector.addEventListener('change', function (e) {
    //     e.preventDefault();
    //     fc_user_id = this.value;
    //     queryParams.set('identity', fc_user_id);
    //     storeHistory();
    //     eventSource.extraParams.identity = fc_user_id;
    //     if (calendar !== undefined) {
    //         calendar.removeAllEventSources();
    //         calendar.addEventSource(eventSource);
    //     } else {
    //         if (reload !== undefined) {
    //             clearInterval(reload);
    //         }
    //         initCalendar();
    //
    //     }
    // });


    document.addEventListener('click', function (e) {
        var close = true;
        $(e.target).parents().each(function () {
            if ($(this).hasClass('fc-timegrid-event') || $(this).hasClass('fc-col-header-cell')) {
                close = false;
            }
        });
        if (close) {
            $('div[role="tooltip"]').tooltip('hide');
        }
    });


    if (start.isBefore(end)) {
        bH = {
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            startTime: document.querySelector('meta[name=w-time-start]').getAttribute('content'),
            endTime: document.querySelector('meta[name=w-time-end]').getAttribute('content'),
        }
    } else {
        bH = [
            {
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                startTime: document.querySelector('meta[name=w-time-start]').getAttribute('content'),
                endTime: '23:59',
            }, {
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                startTime: '00:00',
                endTime: document.querySelector('meta[name=w-time-end]').getAttribute('content'),
            }
        ]
    }


    eventSource = {
        textEscape: true,
        url: $('base').attr('href') + '/schedule',
        method: 'POST',
        extraParams: {
            '_token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            'identity': fc_user_id
        },
        failure: function () {
            if (reload !== undefined) {
                clearInterval(reload);
            }
            bootbox.confirm({
                title: 'Ошибка',
                message: 'Во время загрузки расписания произошла ошибка! Перезагрузить страницу?',
                buttons: {
                    confirm: {
                        label: 'Да',
                    },
                    cancel: {
                        label: 'Нет',
                    }
                },
                callback: function (res) {
                    if (res) {
                        window.location.reload();
                    }
                }
            });
        },
    };


    $(".modal").on('hidden.bs.modal', function () {
        calendar.refetchEvents();
        $('.popover').remove();
    });
    showLoaderFC();
    setTimeout(function () {
        initCalendar();
    }, 1000);
});
